<template>
  <div class="warp">
    <el-form inline size="mini">
      <el-form-item label="Tournament ID:">
        <el-input
          v-model="extraData.id"
          type="number"
          placeholder="Input"
          clearable=""
        ></el-input>
      </el-form-item>
      <el-form-item label="Sport:">
        <select-box
          :value.sync="extraData.sportId"
          search-key="name"
          label-key="sportsName"
          value-key="id"
          :url="STD_SEARCH_SPORT"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500 }"
        />
      </el-form-item>
      <el-form-item label="Region:">
        <select-box
          :value.sync="extraData.regionId"
          search-key="name"
          label-key="name"
          value-key="id"
          :url="STD_SEARCH_REGION"
          :disabled="extraData.id ? true : false"
          :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
        />
      </el-form-item>
      <el-form-item label="Tournament:">
        <select-box
          :value.sync="extraData.tournamentId"
          :url="STD_SEARCH_TOURNAMENT"
          :disabled="extraData.id ? true : false"
          value-key="id"
          label-key="name"
          search-key="name"
          :queryOrderParams="{ page: 1, size: 100 }"
        />
      </el-form-item>
      <el-form-item label="Status:" >
        <el-select v-model="extraData.status" placeholder="Select" clearable="">
          <el-option label="Published" :value="1"></el-option>
          <el-option label="Suspended" :value="2"></el-option>
          <el-option label="Closed" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox
          v-model="extraData.currentStatus"
          label="Current Season"
          :true-label="1"
          false-label=""
          style="margin-right: 10px"
        ></el-checkbox>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="search"
          >Search</el-button
        >
        <el-button @click="add">Add</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="list"
      stripe
      v-loading="loading"
      @sort-change="sort"
      :max-height="tableHeight"
    >
      <el-table-column
        label="ID"
        min-width="80"
        align="center"
        prop="id"
      ></el-table-column>
      <el-table-column
        label="Type"
        min-width="80"
        align="center"
        prop="tournamentTypeName"
      ></el-table-column>
      <el-table-column
        label="Tournament ID"
        min-width="136"
        align="center"
        prop="tournamentId"
      ></el-table-column>
      <el-table-column
        label="Sport"
        min-width="130"
        align="center"
        prop="sportName"
      ></el-table-column>
      <el-table-column
        label="Region"
        min-width="130"
        align="center"
        prop="regionName"
      >
        <template v-slot="{ row }">
          <div>
            {{ row.regionName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        label="Tournament"
        min-width="260"
        align="center"
        prop="tournament"
      ></el-table-column>
      <el-table-column
        label="Season"
        min-width="120"
        align="center"
        prop="seasonName"
      ></el-table-column>
      <el-table-column
        label="Status"
        min-width="120"
        align="center"
        prop="regionName"
      >
        <template v-slot="{ row }">
          <!-- <div>
            <span class="point" :style="{ color: row.status ? 'green' : 'red' }"
              >•</span
            >
            {{ row.status ? 'Published' : 'Closed' }}
          </div> -->
          <el-select v-model="row.status" placeholder="Select" @change="editStatus(row, $event)">
          <el-option label="Published" :value="1"></el-option>
          <el-option label="Suspended" :value="2"></el-option>
          <el-option label="Closed" :value="0"></el-option>
        </el-select>
        </template>
      </el-table-column>

      <el-table-column
        label="Operation"
        min-width="130"
        align="center"
        prop="shortName"
      >
        <template v-slot="{ row }">
          <div class="operation-btns">
            <el-tooltip :content="row.currentStatus? 'Cancel setting as current season': 'Set as current season'" placement="top">
              <span
                class="el-icon-circle-check"
                :style="{ color: row.currentStatus ? 'green' : '' }"
                @click="edit(row, 1)"
              ></span>
            </el-tooltip>
            <el-tooltip content="Edit" placement="top">
              <span class="el-icon-edit-outline" @click="goDetail(row)"></span>
            </el-tooltip>
            <!-- <el-tooltip
              :content="row.status ? 'Close' : 'Publish'"
              placement="top"
            >
              <span
                @click="edit(row, 2)"
                :class="{
                  'el-icon-download': row.status,
                  'el-icon-upload2': !row.status
                }"
              ></span>
            </el-tooltip> -->
            <el-tooltip content="Delete" placement="top">
              <span class="el-icon-delete" @click="leagueDelete(row)"></span>
            </el-tooltip>
            <!-- <el-tooltip content="View" placement="top">
              <span class="el-icon-view"></span>
            </el-tooltip> -->
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" justify="end" style="margin: 20px 0 0 0">
      <el-pagination
        :total="total"
        :current-page="queryData.current"
        :page-size="queryData.size"
        :page-sizes="pageSizes"
        @current-change="changePage"
        @size-change="handleSizeChange"
        :layout="pageLayout"
      ></el-pagination>
    </el-row>
    <el-dialog
      title="Add Tournament"
      width="500px"
      :visible.sync="dialog.show"
      :close-on-click-modal="dialog.clickModal"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialogForm"
        :rules="rules"
        label-width="160px"
        size="mini"
        :ref="dialog.ref"
      >
        <el-form-item label="Tournament ID:">
          <el-input
            placeholder="Please typing in tournament ID"
            v-model="dialogForm.tournamentId"
            @change="getTournament"
            clearable=""
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="Sport:" prop="sportId">
          <select-box
            :url="STD_SEARCH_SPORT"
            :queryOrderParams="{ page: 1, size: 500 }"
            :value.sync="dialogForm.sportId"
            :label="dialogForm.sportsName"
            :disabled="!!dialogForm.tournamentId && selectFlag"
            search-key="name"
            label-key="sportsName"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Region:" prop="regionId">
          <select-box
            :url="STD_SEARCH_REGION"
            :queryOrderParams="{ page: 1, size: 500, deleteFlag: 1 }"
            :value.sync="dialogForm.regionId"
            :label="dialogForm.regionName"
            :disabled="!!dialogForm.tournamentId && selectFlag"
            search-key="name"
            label-key="name"
            value-key="id"
          />
        </el-form-item>
        <el-form-item label="Tournament:" prop="tournamentId" required>
          <select-box
            :value.sync="dialogForm.tournamentId"
            ref="tournamentIdSelect"
            :url="STD_SEARCH_TOURNAMENT"
            @change="selectFlag = false"
            :disabled="(!!dialogForm.tournamentId && selectFlag) || !dialogForm.sportId"
            value-key="id"
            label-key="name"
            search-key="name"
            :queryOrderParams="{ page: 1, size: 100, sportId: dialogForm.sportId }"
          />
        </el-form-item>
        <el-form-item label="Season:" prop="seasonId" required>
          <select-box
            :value.sync="dialogForm.seasonId"
            :url="SEASON_LIST"
            value-key="code"
            label-key="value"
            search-key="value"
            :queryOrderParams="{ page: 1, size: 100 }"
          />
        </el-form-item>
        <el-form-item label="Current Season:" prop="currentStatus" required>
          <el-select
            v-model="dialogForm.currentStatus"
            placeholder="Select"
            clearable=""
          >
            <el-option :value="1" label="Yes"></el-option>
            <el-option :value="0" label="No"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Tournament Type:" prop="tournamentType" required>
          <el-select
            v-model="dialogForm.tournamentType"
            placeholder="Select"
            clearable=""
          >
            <el-option :value="1" label="Cup"></el-option>
            <el-option :value="2" label="League"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Leg:" prop="leg" required>
          <template #label>Leg: <el-tooltip content="Number of leg in playoff" placement="bottom"><i class="el-icon-warning"></i> </el-tooltip></template>
          <el-select
            v-model="dialogForm.leg"
            placeholder="Select"
            filterable=""
            default-first-option=""
            clearable=""
          >
            <el-option :value="1" label="1"></el-option>
            <el-option :value="2" label="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="dialogSubmit"
            :loading="dialog.isLoading"
            >Confirm</el-button
          >
          <el-button @click="dialogHide">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  leagueList as getData,
  leagueSave as addData,
  leagueSave as editData,
  leagueDelete,
  tournament
} from '@/service/seasonManagement'
import TableMixin from '@/mixins/standard/tableSelect'
// import { InputAutocomplete } from '@/components'
// import { cloneDeep } from 'lodash'
// import { deleteMatch } from '@/service/standard'
export default {
  name: 'Tournament',
  mixins: [TableMixin],
  // components: { InputAutocomplete },
  data() {
    return {
      methodStr: 'editTournament',
      editRowIndex: null,
      expandData: {},
      selectFlag: true,
      extraData: {
        sportId: '',
        regionId: '',
        tournament: '',
        status: '',
        currentStatus: 1
      },
      rules: {
        // name: [
        //   {
        //     message: 'Please typing in tournament name',
        //     required: true,
        //     trigger: 'blur',
        //     pattern: '[^ \x22]+'
        //   }
        // ]
        // sportId: [
        //   {
        //     message: 'Please select sports',
        //     required: true,
        //     trigger: 'change'
        //   }
        // ],
        // regionId: [
        //   {
        //     message: 'Please select region',
        //     required: true
        //   }
        // ]
      }
    }
  },
  watch: {
    'extraData.id'() {
      if (this.extraData.id) {
        this.extraData.name = ''
        this.extraData.sportId = ''
        this.extraData.regionId = ''
      }
    }
  },
  methods: {
    getData,
    addData,
    editData,
    editStatus(row, e) {
      editData({id: row.id, status: e}).then(res => {
        if(res.code === 0) {
          this.$message.success('Success')
        }
      })
    },
    goDetail(row) {
      const {href} =  this.$router.resolve({
        path: 'season-management/detail',
        query: { info: JSON.stringify(row) }
      })
      window.open(href, '_blank')
    },
    add() {
      this.dialogAdd()
      this.$nextTick((e) => {
        this.$set(this.dialogForm, 'leg', 1)
        this.$set(this.dialogForm, 'tournamentType', 2)
        this.$set(this.dialogForm, 'currentStatus', 0)
      })
    },
    getTournament(e) {
      if(!e) {
        return 
      }
      this.selectFlag = true
      this.dialogForm.tournamentId = +e
      tournament({ id: e }).then((res) => {
        console.log(res)
        if (res.code === 0 && res.data.records) {
          let list = res.data.records
          if(list.length === 0) {
            this.$message.error('Not found Tournament')
            list = [{}]
          }
          const [{ name, sportId, sportsName, regionId, regionName }] = list
          this.$refs.tournamentIdSelect.optionData = list
          this.$nextTick((e) => {
            this.$refs.tournamentIdSelect.$refs.select.selectedLabel = name
          })
          console.log(sportId)
          this.$set(this.dialogForm, 'sportId', sportId)
          this.$set(this.dialogForm, 'regionId', regionId)
          this.$set(this.dialogForm, 'sportsName', sportsName)
          this.$set(this.dialogForm, 'regionName', regionName)
        }
      })
    },
    getList() {
      this.loading = true
      const extraData = this.fliterParams(this.extraData) || {}
      const params ={ ...this.queryData, ...extraData }
      if(extraData.id) {
        params.tournamentId = extraData.id
      }
      this.getData(this.fliterParams(params, ['id', 'current', 'size']))
        .then((res) => {
          const data = res.data
          if (res.code === 0) {
            this.list = data.records
            this.list = this.list.map((item) => ({
              ...item,
              switchStatus: item.isVisible === 1
            }))
            this.total = data.total
            this.loading = false
          }
        })
        .catch((err) => {
          this.list = []
          this.loading = false
        })
    },
    leagueDelete(row) {
      const that = this
      this.$confirm('Delete this season? ', '', {
        callback(e) {
          if (e === 'confirm') {
            leagueDelete({ id: row.id }).then((res) => {
              that.getList()
              if (res.code === 0) {
                that.$message.success('Success')
              }
            })
          }
        }
      })
    },
    edit(row, type) {
      const data = {
        id: row.id
      }
      if (type === 1) {
        data.currentStatus = row.currentStatus ? 0 : 1
      }
      if (type === 2) {
        data.status = row.status ? 0 : 1
      }
      console.log(data, row)
      this.editData(data).then((res) => {
        this.getList()
        if (res.code === 0) {
          this.$message.success('Success')
        }
      })
      // this.dialogEdit(data)
    },
    async dialogSendData() {
      let data
      if (this.dialogForm.tournamentLevel === '') {
        this.dialogForm.tournamentLevel = 0
      }
      try {
        if (this.dialog.isEdit) {
          data = await this.editData(this.dialogForm)
        } else {
          data = await this.addData(this.dialogForm)
        }

        if (data.code === 0) {
          this.getList && this.getList()
          this.dialogHide()
          this.$message({
            message: 'Successful operation',
            type: 'success'
          })
        } else {
          this.dialog.isLoading = false
        }
      } catch (error) {
        this.dialog.isLoading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table-team-logo {
  height: 32px;
  width: 32px;
}
/deep/ .el-table__expanded-cell {
  padding-left: 10px;
}
.operation-btns {
  span {
    font-size: 16px;
    font-weight: bold;
    display: inline-block;
    margin: 0 5px;
    cursor: pointer;
    color: #999;
    &:hover {
      opacity: 0.7;
    }
  }
}
</style>